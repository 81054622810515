@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "leaflet/dist/leaflet.css";


html, body {
  height: 100%;
}

.trek-detail-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}
.fixed-inquiry {
  position: fixed;
  top: 140px;
  right: 40px;
  width: 320px;
  z-index: 999;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: top 0.2s ease-out;
}

.fixed-inquiry.stop {
  position: absolute;
  top: auto;
  bottom: 200px; /* Ajuste selon le footer */
}

/* Responsive */
@media (max-width: 992px) {
  .fixed-inquiry,
  .fixed-inquiry.stop {
    position: static;
    top: auto;
    right: auto;
    width: 100%;
    margin-top: 2rem;
  }

  .inquiry-form{
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
  }
}


.navbar{
  margin: 0 !important;
  padding: 0 !important;
/*👉  margin: 15px !important;👈*/
  font-family: "Lora", serif;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);

}
@media only screen and (min-width: 1200px) {
  nav{
    height: 60px;
  }
}

/* Banièrre de contact au dessus du menu */



.instruction {
  color: #727982;
}
@media(max-width: 901px) {
  .secondcontainerbanner{
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
  .firstRow-secondContainer{
    display: flex !important;
    width: 100% !important;
  }
  .secondRow-secondContainer{
    
    display: flex !important;
    width: 100% !important;
  }
  .containerTextMail{
    font-size: 13px;
    margin: 0px;
    padding: 0px;

  }

}
.upperbannercontainer{
/*👉  display: flex;
  flex-direction: row;👈*/
  width: 100%;
  background-image: radial-gradient( circle 400px at 6.8% 8.3%,  rgba(255,244,169,1) 0%, rgba(255,244,234,1) 100.2% );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    font-family: "Lora", serif;

}
.firstRow-secondContainer p, .secondRow-secondContainer p{
  margin: 0px;
  padding: 0px;
}
.styleIcon{
  margin-right:  15px;
}
.secondcontainerbanner{
  padding: 8px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.firstRow-secondContainer{
  display: flex;
  justify-content: center;
  width: 50%;
}
.secondRow-secondContainer{
  display: flex;
  justify-content: center;
  width: 50%;
}
.containerMailInfo, .containerPhoneInfo{
  display: flex;
}
@media screen and (width: 950px) {
.secondcontainerbanner{
  display: flex;
  flex-direction: column;
  align-items: start;
}
.firstRow-secondContainer, .secondRow-secondContainer{
  display: flex;
  width: 100%;
}
}




.general_container_navBar{
  
  display: flex !important;
  flex-direction: column !important;
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 100000000 !important; /* Ajoutez une couleur de fond pour éviter la transparence */
    /* border: 1px solid red !important; */
}
.firstRowMenu, .secondRowMenu{
  display: flex;
}
.firstRowMenu {
  background-color: #024168;
}
.navbar {
  width: 100% !important;

}

/* Effet de soulignement progressif */
/* Appliquer le soulignement uniquement aux liens normaux, PAS aux dropdowns */
.nav-link:not(.dropdown-toggle) {
  position: relative;
  display: inline-block;
  font-weight: normal;
  transition: font-weight 0.3s ease;
}

/* Soulignement au survol */
.nav-link:not(.dropdown-toggle)::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 3px;
  /* background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(54,123,252,1) 16.3%, rgba(0,41,118,1) 100.2% );
   */
   background-image: linear-gradient( 89.5deg,  rgba(131,204,255,1) 0.4%, rgba(66,144,251,1) 100.3% );
  transition: width 0.3s ease-in-out;
}

/* Apparition progressive du soulignement */
.nav-link:not(.dropdown-toggle):hover::after {
  width: 100%;
}

/* Disparition progressive du soulignement */
.nav-link:not(.dropdown-toggle):not(:hover)::after {
  width: 0;
  transition: width 0.3s ease-in-out;
}

/* Mettre en gras l'élément actif */
.nav-link.active {
  font-weight: bold;
}
/* Supprimer le contour bleu autour des dropdowns au clic */
.navbar .nav-item .dropdown-toggle:focus,
.navbar .nav-item .dropdown-toggle:active {
  outline: none !important;
  box-shadow: none !important;
}

/* Description des treks */
.description-trek{
  margin: 30px 0px;
  color: #727982;
  font-style: "Lora", serif;

}
.trekBigTitle, .regionBigTitle{
  color: #0f6987;
  font-style: "Lora", serif;
}


a{
  color:#0f6987 !important;
  text-transform:uppercase;
}

.dropdown-menu{
  background-color: white !important;
  z-index: 1000 !important;
}

@media only screen and (width: 1200px) {
  nav{
    border: none;
  }
  .dropdown-menu{
    background-color: transparent !important;
    border: none !important;
    z-index: 1000 !important;
  }
}


header{
  background-color: #F4F7F3;
}

.logoContainer{
  padding: 10px;
}
.logoContainer img{
  width: 70px;
}
.carousel-inner{
  position: relative;
  height: 100vh !important;
  object-fit: cover !important;
}
.carousel-caption h2, .carousel-caption p{
  background: rgb(197,255,254);
  background: linear-gradient(90deg, rgba(197,255,254,1) 50%, rgba(0,212,255,1) 100%);
  -webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  font-family: "Lora", serif;
}
.carousel-item img {
  width: 100%;
  height: 100vh; /* Ajuste la hauteur selon tes besoins */
  object-fit: cover; /* Coupe l'image et la centre */

}
.parallax-img {
  transition: transform 0.3s linear;
  will-change: transform;
}
.carousel-item{
  position: relative;
  Z-index: 0;
}

/* Permet d'afficher le menu dropdown quand on survole le lien */
.navbar-nav .dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

/* Empêche le dropdown de se fermer immédiatement */
.dropdown-menu {
  display: none;
  transition: all 0.3s ease-in-out;
}

/* Ajustement du dropdown pour éviter qu'il clignote */
.navbar-nav .dropdown:hover > .nav-link {
  color: #0f6987 !important;
}
.navbar-toggler:focus, 
.navbar-toggler:active {
  outline: none !important;  /* Supprime l'effet de focus */
  box-shadow: none !important; /* Supprime l'ombre blanche */
}

.navbar-toggler {
  background-color: #024168 !important;
  border-radius: 100px !important;
  

}

/* 🔀 Burger → Croix */
.navbar-toggler.open .navbar-toggler-icon {
  background-image: none;
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-toggler.open .navbar-toggler-icon::before,
.navbar-toggler.open .navbar-toggler-icon::after {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 2px;
  background-color: #92AABC;
  transition: transform 0.3s ease;
}

.navbar-toggler.open .navbar-toggler-icon::before {
  transform: rotate(45deg);
}

.navbar-toggler.open .navbar-toggler-icon::after {
  transform: rotate(-45deg);
}


/* 🎬 Animations */
@keyframes slideFadeDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideFadeUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}



.a_container_logo{
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: center;
}
.upperbox{
  display: flex;
  justify-content:space-between;
}
.upperbox a{
  text-decoration: none !important;

}

.societyName span{
  display: flex;
  text-decoration: none !important;
  font-family: "Lora", serif;
}


.carousel-item{
  position: relative !important;
}


.carousel-caption {
  position: absolute !important;
  bottom: 0px !important; /* Place le texte en bas de l’image */
  left: 0px !important;
  width: 100%; /* Prend toute la largeur */
  background: rgba(0, 0, 0, 0.6); /* Fond semi-transparent */

  text-align: center; /* Centre le texte */
  z-index: 1000 !important;
}


.carousel-caption h2 {
  font-size: 2rem;
  font-weight: bold;
}

.carousel-caption p {
  font-size: 1.2rem;
  margin-bottom: 50px;
}



.glassButton{
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.9) !important;
border-radius: 50px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(4px);
-webkit-backdrop-filter: blur(4px);
z-index: 10000 !important;
padding: 8px 16px;
font: "Poppins";
}


/* Carte du Népal */
.generalContainerMapAndTreks{
  display: flex !important;
  flex-direction: row !important;
  font-family: "Lora", serif;
  /* border: 1px solid red !important; */
}

.containerMap{
  display: flex !important;
  width: 50%;
  margin: 20px;
  /* border: 1px solid red !important; */
}
.treks_available{
  position: relative;
  margin: 10px;
  padding: 10px;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  width: 50%;
  height: 610px;
  color: #727982;
  font-style: "Lora", serif;

/*👉  box-shadow: 15px 19px 33px -13px rgba(0,0,0,0.1);👈*/
}
.description_treks{
  width: 100%;
}


.description_treks h3{
  font-size: 18px;
  font-style: "Lora", serif;
  color: #5b3c11;

}
.description_treks ul, li{
  color: #024168;

}

/* Responsivité de l'application en mode smartphone */
@media(max-width: 991px) {

  .carousel-caption p {
    font-size: 1rem;
    margin-bottom: 50px;
    padding: 0px 10px;
  }
  .titleNepalMapTrek h2{
    font-size: 1rem;

  }
  .titleNepalMapTrek{
    display: flex;
    flex-direction: column;
  }
  
  .generalContainerMapAndTreks{
    display: flex !important;
    flex-direction: column !important;
  }
  .treks_available{
    display: flex;
    width: 100%;
    height: 100%;
  }
  .containerMap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0px;
  }
  .ulTrek{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    width: 100%;

  }
  .descriptionAnddifficulty{
    /* border: 1px solid red; */
  }
  .btnSeeMore_DescrTrekMap{
    /* border: 1px solid red; */

  }
  .containerDifficultyAndButton{
    display: flex;
    justify-content:space-around;
    align-items: center;
    width: 100%;
  }
  .compassAndTitleContainer{
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .p1compassAndTitleContainer{
    display: flex !important;
    width: 100%;
    
  }
  .p2compassAndTitleContainer{
    display: flex !important;
    width: 100%;
    opacity: 1 !important;

  }
  .main-container-compass{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    margin: auto !important;

  }
  .container_loti_file{
    width: 500px !important;

  }
  .lottieFile{
    width: 300px !important;
  }

}

/* mode grand écran */
.containerDifficultyAndButton{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/*


descriptionAnddifficulty
  descriptionAnddifficulty1
  descriptionAnddifficulty2

btnSeeMore_DescrTrekMap
*/






.titleNepalMapTrek{
  display: flex;
  justify-content: center;
  align-items: center;

  color: #024168;
  font-family: "Lora", serif;
  margin:auto;
  text-align: center;
  margin: 30px 0px 0px 0px!important;
  padding: 0px !important;

}

.titleNepalMapTrek span{
  margin: 0px !important;
  padding: 0px !important;
}




.titleNepalSection {
  color:#024168;
  font-family: "Lora", serif;
  padding: 0px;
  margin-top: 40px;
}
.topicContainer {
  color:#727982;
  font-family: "Lora", serif;
  padding: 0px 20px;
}
/* Custom tab color */
.list-group-item.active {
  background-color: #024168 !important;
  border-color: #024168 !important;
  color: #ffffff !important;
}

/* Optional: hover effect for non-active tabs */
.list-group-item-action:hover {
  background-color: #f0f8ff;
  color: #024168;
}
.list-group-item {
  transition: background-color 0.3s ease, color 0.3s ease;
}
.tab-pane{
  min-height: 560px;
}
@media screen and (width: 768px) {
  .tab-pane{
    min-height:auto;
  }
}




.shoes-icons {
  font-size: 1.5em; /* Agrandir l'icône */
  display: flex;
  gap: 5px; /* Espacement entre les icônes */
  margin-top: 5px;
}


.difficulty {
  font-weight: bold;
  margin-right: 5px;
}



/* Lottie file drapeau du Népal */
.containerFlagAndTitle{
  /*👉margin: auto;👈*/
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
/*👉  border: 1px solid red;👈*/
  padding-bottom: 70px;

}
.compassAndTitleContainer{
  /* border: 1px solid red; */
  display: flex !important;
  flex-direction: row;
  justify-content:space-between !important;
  align-items:center !important;
  width: 100%;
}
.containerFlags{
  position: relative;
}
.p1compassAndTitleContainer{
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  width: 80%;
  /* border: 1px solid red; */
}
.p2compassAndTitleContainer{
  display: flex;
  width: 20%;
  padding-top: 30px;
  /* border: 1px solid red; */

}


.container_flag_and_title{
position: relative;
}

.container_flag_and_title .insider_flag{
  position: absolute;
  top: 0;
  right: 0;
}

/* Les commentaires des clients  */
.arrow-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #f2f2f2;
  transition: opacity 0.3s;
  margin: 0 15px;
}

.arrow-btn:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.card {
  text-align: center;
  min-height: 250px;
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
}









/* Formulaire d'envoi de message */
.styleSendMessageForm{
  margin: 20px;
  text-align: center;
  font-family: "Lora", serif;
  color: #727982;
}



/* Animation du menu qui reste fixe et du logo qui descend */
/* Style initial du logo */
/* Fixe la navbar et Upper lorsque le logo atteint le menu */
.fixed-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: white;


  z-index: 999;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* Conteneur du logo qui descend */
.upper-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: top 0.3s ease-out, height 0.3s ease-out;
}

/* Ajuste la hauteur initiale du logo */
.logoContainer img {
  transition: height 0.3s ease-out;
}







/* Importation de la police */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@200&display=swap");



.form-main {
  background: linear-gradient(to bottom, #00000024, #00000024),
    url('../public/mount-everest-north-face-map.webp') no-repeat center;
  background-size: cover;
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: 0.8;
  border: none;
}


.main-wrapper {
  border-radius: 10px;
  padding: 45px;
  width: 70%;
  box-shadow: 0 0 5px 5px #00000020;
  backdrop-filter: blur(5px);
  background-color: #ffffff85;
  border: 1px solid transparent !important;
}






.form-head {
  font-size: 25px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
  margin: 0px 0 25px;
  color: #024168;
}

.form-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}

.form-card {
  position: relative;
  width: 100%;
}

.form-input,
.form-textarea {
  padding: 20px 25px 15px;
  width: 100%;
  border: 1px solid black;
  border-radius: 5px;
  background: transparent;
  outline: none;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  box-sizing: border-box;
}

.form-input:valid,
.form-input:focus,
.form-textarea:valid,
.form-textarea:focus {
  border: 1px solid #515151;
}

.form-label,
.form-textarea-label {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  transition: 0.3s ease-in-out;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: gray;
}

.form-input:valid ~ .form-label,
.form-input:focus ~ .form-label,
.form-textarea:valid ~ .form-textarea-label,
.form-textarea:focus ~ .form-textarea-label {
  color: #515151;
  top: 20%;
  transform: translateY(-70%);
  font-size: 13px;
  line-height: 23px;
  z-index: 1000000;
  /* background-color: transparent; */
}

.form-textarea {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  resize: none;
}
.form-textarea{
  padding-top: 30px;
}
.btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0 0;
}

.btn-wrap button {
  padding: 0 32px;
  font-size: 18px;
  line-height: 48px;
  border: 1px solid transparent;
  font-weight: 600;
  border-radius: 6px;
  transition: all 0.5s ease;
  cursor: pointer;
  box-shadow: 0 0 5px 5px #00000020;
}

.btn-wrap button:hover {
  border: 1px solid #000;
  background: transparent;
}

/* Suppression des styles de remplissage automatique de Chrome */
.form-input:-webkit-autofill,
.form-input:-webkit-autofill:hover,
.form-input:-webkit-autofill:focus,
.form-input:-webkit-autofill:active,
.form-textarea:-webkit-autofill,
.form-textarea:-webkit-autofill:hover,
.form-textarea:-webkit-autofill:focus,
.form-textarea:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}

/* Suppression des flèches sur les inputs de type number */
.form-input::-webkit-outer-spin-button,
.form-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



.contactContainer{
  position: relative;
  display: flex !important;
  flex-direction: row !important;
  width: 100% !important;
  z-index: 1;
/*👉  opacity: 0.8;👈*/
}
.cc1{
  display: flex !important;
  width: 50% !important;
  z-index: 1000000;

}
.cc2{
  display: flex !important;
  width: 50% !important;
   z-index: 1000000;
   background-color: #000 !important;

}

@media(max-width: 991px) {
  .dynamic-word {
    letter-spacing: 5px;

  }
  .contactContainer{
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
  }
  .cc1{
    display: flex !important;
    width: 100% !important;

  }
  .cc2{
    display: flex !important;
    width: 100% !important;
    height: 500px;
  }
  .form-main {
    width: 100%;
    padding-bottom: 30px;
    /* border: 1px solid red; */
  }
  .form-head{
    font-family: "Lora", serif;
    font-size: 18px;
    line-height: 25px;
  }
  .main-wrapper{
    width: 90%;
    
  }
  .reviewMainContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .review-card{
    display: flex;
    align-items: center;
  }

  .reviewtext{
    padding: 0px 10px;
  }
  .containerMap{
    margin: 0px !important;
  }
  .treks_available{
    margin: 0px !important;
  }
  .ulTrek{
    padding: 0px 20px !important;
    list-style-type: none;
  }

  .selfpromote{
    
  }
  .selfpromote p{
    font-size: 12px;
  }
}
.containerRonds{

}
.containerRondsEtComment, .containerImgName{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ulTrek{
  padding: 0px 20px !important;
  list-style-type: none;
}
/*👉paramètres de la carte👈*/
/*👉zoom: 6.3
height: 500px
width: 100%👈*/

.mapcontainer{
  width: 100%;
  position: relative;
}



/* FOOTER */
/*👉container des 2 parties droites et gauche👈*/
.division_parts_footer{
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin: auto;
  /* border: 1px solid red; */
}
.division_parts_footer::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%; /* Partie basse uniquement */
  background-image: repeating-linear-gradient(
    90deg,
    #0C1B2E,
    #0C1B2E 10px,
    transparent 10px,
    transparent 20px
  );
  pointer-events: none;
  z-index: 1;
}

.part_left_footer, .part_right_footer{
  display: flex;
  flex-direction: column;
  width: 50%;
}
.part_right_footer p{

  color: #B6B38D;
  font-family: "Lora", serif;

}
.part_left_footer{
  display: flex;
  justify-content: center;

}
.part_right_footer{
  padding-bottom: 40px;
  z-index: 5;
}
.part_left_footer p, .part_right_footer p {
  margin: 10px auto !important;
}
.part_left_footer{
  /* border-right:   1px solid #f2f2f2; */
}
.part_left_footer p{
  padding: 10px !important;
  text-align: left !important;
}
.part_left_footer img{
  display: flex;
  flex-direction: row;
}


.main_container_footer{
  margin: 20px 0px;
  font-family: "Lora", serif;
  color: #f2f2f2;
  background-color: #0C1B2E;
  /* background-image: linear-gradient( 104.1deg,  rgba(0,61,100,1) 13.6%, rgba(47,127,164,1) 49.4%, rgba(30,198,198,1) 93.3% ); */
  min-height: 150px;
  display: flex !important;
  flex-direction: column !important;
  margin: auto !important;
  text-align: center !important;
  /* border: 1px solid red; */
}

.selfpromote{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  /* border-top: 1px dashed #f2f2f2 !important; */
/* border-top: 1px dashed #045891 !important; */
  /* background-color: #0B1B2E; */
  background-color: black;
  opacity: 0.7;
}
.selfpromote p{
  /* color: #024168; */
  display: flex;

   background-image: radial-gradient( circle 400px at 6.8% 8.3%,  rgba(255,244,169,1) 0%, rgba(255,244,234,1) 100.2% );

-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  font-family: "Lora", serif;
  
}


.reviewMainContainer {
  background-color: #0C1B2E;
  /* width: 100%; */
}
.maxRev{

  background-color: #0C1B2E;
  margin: 0px !important;
  padding: 0px !important;
}

.we_support_container{

  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content:space-around !important;




}
.find_us_container{
/*👉  border: 1px solid red;👈*/
  display: flex;
  flex-direction: row !important;
  align-items:center !important;
  justify-content: center !important;
  z-index: 5;
}
/*👉.we_support_logo{
  height: 50px;
  width: 50px;
  margin: 0px 40px;
}
👈*/
.supportlgcontainer{
  background-color: #FBAB7E !important;
  background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.texteFooterLogos textPath{
  background-image: radial-gradient( circle 341px at 10% 20%,  rgba(132,94,194,1) 0%, rgba(196,243,251,1) 90% );
  -webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  font-family: "Lora", serif;
}
.ssss textPath{
  background-color: #FBAB7E !important;
  background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.arc-text text {
  fill: #B6B38D !important;
}


/* détails des treks */
.breadcrumb{


}
.container-detail-trek{
  margin: 30px;
  background-color: white;
}
.breadcrumb_container{
  margin-top: 140px !important;
  margin: 0px;
}
.breadcrumb_container ol, .breadcrumb_container li{
  background-color: white !important;
}









/* Timeline custom styles */

.timeline-wrapper {
  background: #eaf6ff;
  padding: 2rem;
  border-radius: 15px;
}

.sessions {
  margin-top: 2rem;
  border-radius: 12px;
  position: relative;
}

.sessions li {
  list-style: none;
  padding-bottom: 1.5rem;
  border-left: 1px solid #abaaed;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
}

.sessions li:last-child {
  border: 0;
  padding-bottom: 0;
}

.sessions li::before {
  content: '';
  width: 15px;
  height: 15px;
  background: white;
  border: 1px solid #4e5ed3;
  box-shadow: 3px 3px 0px #bab5f8;
  border-radius: 50%;
  position: absolute;
  left: -10px;
  top: 0px;
}

.sessions .time {
  color: #2a2839;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.sessions p {
  color: #4f4f4f;
  font-family: sans-serif;
  line-height: 1.5;
  margin-top: 0.4rem;
  font-size: 0.9rem;
}


/* Les 14 régions
Karnali
Mahakali
Seti
Bagmati
Janakpur
Gandaki
Bhojpur
Dhawalagiri
Sagarmatha
Mechi
Narayani
Lumbini
Rapti
Bheri

 */



/*👉 Pour les 14 régions penser à mettre les différentes 
 montagnes👈*/



/* Pour élargir le container (pas comme .container Bootstrap) */
.trek-detail-wrapper {
  width: 100%;
  padding: 0 2rem;
}

/* Flex layout */
.trek-layout {
  display: flex;
  gap: 30px;
  align-items: flex-start;
}

/* Partie gauche = contenu principal */
.trek-content {
  flex: 0 1 70%;
  min-width: 0;
}

/* Partie droite = formulaire */
.inquiry-form-wrapper {
  flex: 0 1 30%;
  min-width: 300px;
}
.trek-detail-wrapper, .trek-layout {
  overflow: visible !important;
}
.inquiry-form.sticky {
  position: sticky;
  top: 80px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  align-self: flex-start;
}

/* Responsive */
@media (max-width: 992px) {
  .trek-layout {
    flex-direction: column;
  }

  .inquiry-form-wrapper {
    margin-top: 2rem;
    width: 100% !important;
  }
  .inquiry-form{
    display: flex;
    border: none;
    width: 100% !important;
    position: relative;

  }
}



.oblique-image-section {
  display: flex;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.image-wrapper {
  flex: 1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}



@keyframes fadeIn {
  to {
    opacity: 1;
  }
}



/* Optionally for small screens: stack them */
@media (width: 768px) {
  .oblique-image-section {
    flex-direction: column;
    height: auto;
  }

  .image-wrapper {
    clip-path: none !important;
    height: 200px;
    animation: fadeIn 0.8s ease forwards;
  }

  .image-wrapper + .image-wrapper {
    margin-top: 10px;
  }
}


/*
Responsivité du footer 

main_container_footer
division_parts_footer
part_left_footer
  we_support_container
  find_us_container
part_right_footer
  p
  b-page
selfpromote

*/
@media (max-width:900px) {
  .division_parts_footer{
    display: flex !important;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .part_left_footer{
    display: flex;
    width: 100%;
  }
  .part_left_footer div{
    display: flex;
    width: 100%;
  }
  .we_support_container, .find_us_container{

  }
  .we_support_container {
    display: flex;

    width: 70%;


  }
  .we_support_container{
    display: flex;
    justify-content: center;
    align-items: center !important;
    margin: 0px;
    padding: 10px;
    width: 100%;
  }
  .find_us_container{
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    margin: 0px;
    padding: 10px;
    width: 100%;

  }
  .part_left_footer img{ 
    height: 35px;
    width: 35px;
  }
  .part_right_footer{
    display: flex;
    width: 100% !important;
    padding-bottom: 25px;
  }
  .fb-page{
    width: 100%;

  }
}
.fb-page{
  width: 100% !important;
}

.gallery-container{
  background-color: black;
  /* background-color: #0C1B2E; */


}





.angled-gallery {
  display: flex;
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  gap: 0;             /* <-- assure-toi qu’il n’y a aucun gap */
}

.img-container {
  flex: 1;
  position: relative;
  overflow: hidden;
  transition: opacity 0.5s ease;
  opacity: 0;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/* Formes spécifiques */
.img-container.first {
  clip-path: polygon(0% 0%,100% 0%,100% 0%,81% 100%,0% 100%);
  animation: fadeIn 0.3s ease-in forwards;

}

.img-container.middle {
  clip-path: polygon(22% 0%,100% 0%,78% 100%,0% 100%);
  animation: fadeIn 0.3s ease-in forwards 0.3s;
}

.img-container.last {
  clip-path: polygon(22% 0%,100% 0%,100% 100%,0% 100%);
  animation: fadeIn 0.3s ease-in forwards 0.6s;
}

/* Fade in animation */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}







/* CSS */
.button-55 {
  align-self: center;
  background-color: #fff;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #0f6987;
  cursor: pointer;

  font-family: Neucha, sans-serif;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: .75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-55:hover {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
  transform: translate3d(0, 2px, 0);
}

.button-55:focus {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}

@keyframes pulse-scale {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(0.95);
  }
}


/* CSS */
.button-71 {
  background-color: #664F34;
  border: 0;
  border-radius: 56px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: system-ui,-apple-system,system-ui,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif;
  font-size: 18px;
  font-weight: 600;
  outline: 0;
  padding: 5px 21px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  animation: pulse-scale 2s infinite ease-in-out; 
}

.button-71:before {
  background-color: initial;
  background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 125px;
  content: "";
  height: 50%;
  left: 4%;
  opacity: .5;
  position: absolute;
  top: 0;
  transition: all .3s;
  width: 92%;
}

.button-71:hover {
  box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .button-71 {
    padding: 16px 48px;
  }
}


.scroll-btn-wrapper {
  position: relative;
  top: -30px;
  display: flex;
  justify-content: center;
  z-index: 5;
}
.textBtn{
  margin: 1px;
  padding: 10px;
}
.scroll-to-map-btn {
  background-color: #5b3c11;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 50px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.scroll-to-map-btn:hover {

}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease;
}

.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease;
}



.button-713 {
/*👉  position: absolute !important;
  left: 0px !important;
  bottom: -30px !important;👈*/
/*👉  z-index: 9999999999999999999999999999 !important;👈*/

  background-color: #1C4168;
  border: 0;
  border-radius: 56px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: system-ui,-apple-system,system-ui,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif;
  font-size: 18px;
  font-weight: 600;
  outline: 0;
  padding: 5px 21px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  animation: pulse-scale 2s infinite ease-in-out; 
}

.button-713:before {
  background-color: #1C4168;
  background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 125px;
  content: "";
  height: 50%;
  left: 4%;
  opacity: .5;
  position: absolute;
  top: 0;
  transition: all .3s;
  width: 92%;
}

.button-713:hover {
  box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
  transform: scale(1.05);
}

.button-713 {
  padding: 16px 48px;
}



.button-713 {
  position: relative;
  z-index: 1 !important; /* Important */
}

.button-713:before {
  z-index: 0 !important;
  opacity: 0.1; /* Pour garder l'effet sans gêner le texte */
}


.button-712 {
  background-color: #8DB3D0;
  transform: scale(1);
  border: 0;
  border-radius: 56px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: system-ui,-apple-system,system-ui,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif;
  font-size: 18px;
  font-weight: 600;
  outline: 0;
  padding: 5px 21px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  animation: pulse-scale 2s infinite ease-in-out; 
}

.button-712:before {
  background-color: initial;
  background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 125px;
  content: "";
  height: 50%;
  left: 4%;
  opacity: .5;
  position: absolute;
  top: 0;
  transition: all .3s;
  width: 92%;
}

.button-712:hover {
  box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .button-712 {
    padding: 16px 48px;
  }
}











/* CSS */
.button-86 {
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin: auto;
  text-decoration: none !important;
}

.button-86::after,
.button-86::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

.button-86::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  /* background: #28282d; */
  background-color: #024168;
  border-radius: 30px;
}
a.no-underline {
  text-decoration: none;
}
.button-86::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.button-86:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.button-86:hover::after {
  border-radius: 30px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.button-86:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}






.back-to-top-button {
  font-size: 2rem; /* text-4xl */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 30px;
  right: 40px;
  height: 55px;
  width: 55px;
  z-index: 10000000000000;
  padding: 1rem; /* p-4 */
  background-color: #024168; /* ou mets ce que tu veux */
  color: white;
  border-radius: 9999px; /* rounded-full */
  border: none;
  cursor: pointer;

  /* focus:outline-none + focus:ring */
  outline: none;
  transition: box-shadow 0.2s ease;
}

.back-to-top-button:focus {
  box-shadow: 0 0 0 2px #374151; /* gray-700 ring */
}

.back-to-top-button:focus-visible {
  outline: none;
}

/* Pour accessibilité: équivalent de sr-only */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}


.forWind{
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  /* border: 1px solid red; */
}

.main_container_title_and_flag_nepal{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 200px !important;
  padding: 20px 0px;

}
.container_title_aboutNepal h2{

  padding: 0px !important;
  display: flex !important;
  justify-content:end !important;
  align-items: end !important;

}
.lottieFile{
  height: 140px;

}
.containerAboutNepal{
  color: #727982;


}
.reviewTitle{
/*👉  color: #f2f2f2;👈*/
/*👉background-image: radial-gradient( circle 341px at 10% 20%,  rgba(132,94,194,1) 0%, rgba(196,243,251,1) 90% );

-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  font-family: "Lora", serif;👈*/
  color: #C3F3FB;
  background-image: radial-gradient( circle 400px at 6.8% 8.3%,  rgba(255,244,169,1) 0%, rgba(255,244,234,1) 100.2% );

-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
 font-family: "Lora", serif;
}

/*👉#anchornepalmap{
  position: relative !important;
}👈*/

.main-container-compass{
  width: 180px;
  margin: 0px 40px;
  cursor: pointer;
  opacity: 0.7;
}

/*👉.container_loti_file{
  position: absolute;
  top: 0px;
}
👈*/



/* Style page 404 */
.not-found-container {
  min-height: calc(100vh - 200px); /* tient compte du footer */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.not-found-container h1 {
  font-size: 8rem;
  font-weight: bold;
  color: #024168;
  margin-bottom: 0.5rem;
}

.not-found-container h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.not-found-container p {
  color: #666;
  margin-bottom: 2rem;
}

.back-home-btn {
  background-color: #024168;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-home-btn:hover {
  background-color: #03609d;
}

.division_parts_footer{
  background: repeating-linear-gradient(
    to bottom,
    #0C1B2E 0px,
    #0C1B2E 10px,
    transparent 10px,
    transparent 20px
  );
  background-position: 0 50%;
  position: relative;
  z-index: 1;
  color: #fff;
}



.containerTripAdvisor{
  position: absolute;
  width: 100%;
  bottom: 20px;
  left: 0px;
}
.containerFlexTA{
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  align-items: center;
}
.containerFlexTA p{
  padding-top: 10px;
}
.tripAdvisorLogo{
  display: flex;
  width: 50px;
}
.map-info-box {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: 20px;
  background: white;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  z-index: 100;
  width: 350px;
  font-family: "Lora", serif;
  font-size: 0.9rem;
}
.map-info-box h3{
  color: #024168;
}
.map-info-box p{
  color: #727982;
}
.map-links a{
  text-transform: lowercase !important;
  text-decoration: none;
}
.containerMIB{
  display: flex;
  flex-direction: row;
  color: #727982;
}
.vmtripadvisor{
  text-transform: lowercase !important;
  text-decoration: none;
  color:#0C1B2E;

}

.miB2{
  color: #727982 !important;

}


.containerAboutTheOwner{
  min-height: 90vh;

  padding: 200px 20px 0px 150px;
  background-color:#C3F3FB;
}

.styleForOwnerPrez{
  display: flex;
  flex-direction: row;

}
.r1-styleForOwnerPrez{
  display: flex;
  width: 40%;
}
.r1-styleForOwnerPrez img{
  max-width: 100%;
  padding: 30px;
  border-radius: 500px;
}
.r2-styleForOwnerPrez{
  display: flex;
  flex-direction: column;
  width: 60%;
}
.owner_bio{
  font-family: "Lora", serif;
  font-size: 1rem;
  color: #727982;

}


.word-transition-enter {
  opacity: 0;
  transform: translateY(-100%);
}
.word-transition-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: all 500ms ease;
}
.word-transition-exit {
  opacity: 1;
  transform: translateY(0%);
}
.word-transition-exit-active {
  opacity: 0;
  transform: translateY(100%);
  transition: all 500ms ease;
}
.dynamic-word {
  position: relative;
  min-width: 210px;
  /* white-space: nowrap; */
  text-align: center;


  font-family: "Lora", serif;
}